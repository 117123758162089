import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { ReactComponent as Received } from "../../assets/svg/received.svg";
import { ReactComponent as Created } from "../../assets/svg/created.svg";
import { ReactComponent as OnWay } from "../../assets/svg/on_way.svg";
import { ReactComponent as Delivered } from "../../assets/svg/delivered.svg";
import { ReactComponent as Arrow } from "../../assets/svg/arrow.svg";
import { ReactComponent as Cancel } from "../../assets/svg/cancel.svg";
import { Styled } from "./_styles";
import { ILogs } from "../../interfaces";
import moment from "moment";
import { IWidgetContext, WidgetContext } from "../../context";
import { LG } from "../../constants";

export interface IStatusItemsProps {
  logs?: ILogs[];
}

export const StatusItem: FC<IStatusItemsProps> = ({ logs }) => {
  const {
    config: { linkColor, iconActiveColor, lg },
  } = useContext<IWidgetContext>(WidgetContext);
  const [openMore, setOpenMore] = useState(false);
  const [noRepeatLogs, setNoRepeatLogs] = useState<ILogs[]>([]);
  const [openMoreBlock, setOpenMoreBlock] = useState(false);
  const [noRepeatLogsBlock, setNoRepeatLogsBlock] = useState<ILogs[]>([]);

  const statusCodes: any = logs?.reduce((acc, log) => {
    if (log.status === 95 && !!(acc as any)?.[log.status]) {
      return {
        ...acc,
      };
    }
    return {
      ...acc,
      [log.status]: {
        date: log.date,
      },
    };
  }, {});

  const isCreated = statusCodes && (statusCodes["40"] !== undefined || statusCodes["41"] !== undefined);
  const isOnWay = statusCodes && !!statusCodes["60"];
  const isDelivered = statusCodes && (statusCodes["110"] !== undefined || statusCodes["115"] !== undefined);
  const isReceived = statusCodes && !!statusCodes["100"];
  const isDeliveredInfo = statusCodes && !!statusCodes["90"];
  const isOnWayInfo = statusCodes && !!statusCodes["60"];
  const isCancel = statusCodes && !!statusCodes["50"];

  const noRepeatLogsFilter = useCallback(() => {
    if (!logs) return [];
    const res = [];
    for (let i = 0; i <= logs.length - 1; i++) {
      if (

      /*   logs[i]?.outer_status_name !== logs[i + 1]?.outer_status_name &&
        new Date(logs[i]?.date) < new Date(logs[i + 1]?.date) && logs[i]?.block === 2 */
        (i === 0 && logs[i]?.block === 2) ||
                (logs[i]?.outer_status_name !== logs[i - 1]?.outer_status_name &&
                        new Date(logs[i]?.date) > new Date(logs[i - 1]?.date) && logs[i]?.block === 2)
      ) {
        res.push(logs[i]);
      }
    }
    return res.reverse();
  }, [logs]);

  useEffect(() => {
    const result = noRepeatLogsFilter();
    setNoRepeatLogs(result);
  }, [noRepeatLogsFilter]);

  const noRepeatLogsFilterBlock = useCallback(() => {
    if (!logs) return [];
    const res = [];
    for (let i = 0; i <= logs.length - 1; i++) {
      if (

//         logs[i]?.outer_status_name !== logs[i + 1]?.outer_status_name &&
//         new Date(logs[i]?.date) < new Date(logs[i + 1]?.date) && logs[i]?.block === 3
        (i === 0 && logs[i]?.block === 3) ||
        (logs[i]?.outer_status_name !== logs[i - 1]?.outer_status_name &&
                new Date(logs[i]?.date) > new Date(logs[i - 1]?.date) && logs[i]?.block === 3)
      ) {
        res.push(logs[i]);
      }
    }
    return res.reverse();
  }, [logs]);

  useEffect(() => {
    const result = noRepeatLogsFilterBlock();
    setNoRepeatLogsBlock(result);
  }, [noRepeatLogsFilterBlock]);

  return (
    <Styled.StatusItemWrapper>
        {isCancel ? (
            <Styled.StatusItem iconActiveColor={iconActiveColor}>
              <Styled.StatusIcon
                iconActiveColor={iconActiveColor}
                isActive={isCancel}
              >
                <Cancel fill={isCancel ? "#000" : "#7E7E7D"} />
              </Styled.StatusIcon>
              <Styled.Point isActive={isCancel}>{LG[lg].cancel}</Styled.Point>
              {isCancel && (
                <Styled.Date>
                  {moment(statusCodes?.["50"]?.date).format("DD.MM.yyyy")}
                </Styled.Date> ) }
            </Styled.StatusItem>
            ):(
            <>
            <Styled.StatusItem
                       iconActiveColor={iconActiveColor}
                       border
                       isActive={isReceived}
                     >
                       <Styled.StatusIcon
                         iconActiveColor={iconActiveColor}
                         isActive={isReceived}
                       >
                         <Received fill={isReceived ? "#000" : "#7E7E7D"} />
                       </Styled.StatusIcon>
                       <Styled.Point isActive={isReceived}>{LG[lg].received}</Styled.Point>
                       {isReceived && (
                         <Styled.Date>
                           {moment(statusCodes?.["100"]?.date).format("DD.MM.yyyy")}
                         </Styled.Date>
                       )}
                     </Styled.StatusItem>
                     <Styled.StatusItem
                       iconActiveColor={iconActiveColor}
                       border
                       isActive={isDelivered}
                       openMore={openMoreBlock}
                     >
                       <Styled.StatusIcon
                         iconActiveColor={iconActiveColor}
                         isActive={isDelivered}
                       >
                         <Delivered fill={isDelivered ? "#000" : "#7E7E7D"} />
                       </Styled.StatusIcon>
                       <Styled.Point isActive={isDelivered}>{LG[lg].delivered}</Styled.Point>
                       {isDelivered && (
                         <Styled.Date>
                           {moment(statusCodes?.["110" || "115"]?.date).format("DD.MM.yyyy")}
                         </Styled.Date>
                       )}
                       {isDeliveredInfo && (
                         <>
                           <Styled.MoreBlock
                             linkColor={linkColor}
                             onClick={() => setOpenMoreBlock((prev) => !prev)}
                           >
                             {LG[lg].more} <Arrow fill={linkColor} />
                           </Styled.MoreBlock>

                           <Styled.WayBlock openMoreBlock={openMoreBlock}>
                             {noRepeatLogsBlock?.map((item, index) => (
                               <Styled.WayItem key={index}>
                                 <Styled.Circle iconActiveColor={iconActiveColor} />
                                 <span>{item?.outer_status_name}</span>
                                 {moment(item?.date).format("DD.MM.yyyy")}
                               </Styled.WayItem>
                             ))}
                           </Styled.WayBlock>
                         </>
                       )}


                     </Styled.StatusItem>
                     <Styled.StatusItem
                       iconActiveColor={iconActiveColor}
                       border
                       isActive={isOnWay}
                       openMore={openMore}
                     >
                       <Styled.StatusIcon iconActiveColor={iconActiveColor} isActive={isOnWay}>
                         <OnWay fill={isOnWay ? "#000" : "#7E7E7D"} />
                       </Styled.StatusIcon>
                       <Styled.Point isActive={isOnWay}>{LG[lg].onTheWay}</Styled.Point>
                       {isOnWay && (
                         <Styled.Date>
                           {moment(statusCodes?.["45" || "46" || "60" || "70" || "80" || "90" || "95"]?.date).format("DD.MM.yyyy")}
                         </Styled.Date>
                       )}
                       {isOnWayInfo && (
                         <>
                           <Styled.More
                             linkColor={linkColor}
                             onClick={() => setOpenMore((prev) => !prev)}
                           >
                             {LG[lg].more} <Arrow fill={linkColor} />
                           </Styled.More>
                           <Styled.Way openMore={openMore}>
                             {noRepeatLogs?.map((item, index) => (
                               <Styled.WayItem key={index}>
                                 <Styled.Circle iconActiveColor={iconActiveColor} />
                                 <span>{item?.outer_status_name}</span>
                                 {moment(item?.date).format("DD.MM.yyyy")}
                               </Styled.WayItem>
                             ))}
                           </Styled.Way>
                         </>
                       )}
                     </Styled.StatusItem>
                     <Styled.StatusItem iconActiveColor={iconActiveColor}>
                       <Styled.StatusIcon
                         iconActiveColor={iconActiveColor}
                         isActive={isCreated}
                       >
                         <Created fill={isCreated ? "#000" : "#7E7E7D"} />
                       </Styled.StatusIcon>
                       <Styled.Point isActive={isCreated}>{LG[lg].created}</Styled.Point>
                       {isCreated && (
                         <Styled.Date>
                           {moment(statusCodes?.["20" || "40"]?.date).format("DD.MM.yyyy")}
                         </Styled.Date>
                       )}
                     </Styled.StatusItem>
                     </>
                     )
          }
    </Styled.StatusItemWrapper>
  );
};
