import styled from "styled-components";

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.29);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
`

const ModalContent = styled.div`
  width: 745px;
  height: 398px;
  background-color: white;
  border-radius: 10px;
  padding: 41px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  text-align: center;
`

const ModalInfo = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ModalTitle = styled.h3`
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.4375rem;
`

const ModalDescription = styled.p`
  font-weight: 400;
  font-size: 1.5625rem;
  line-height: 2rem;
  margin-top: 1rem;
`

export const Styled = { ModalDescription, ModalTitle, ModalInfo, ModalWrapper, ModalContent }